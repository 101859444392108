<template>
  <div class="max-w-5xl px-4">
    <h2 class="text-5xl uppercase font-extrabold">Leadership</h2>
    <div class="mt-10">
      <p v-if="leadership.attributes">
        <VueShowdown :markdown="`${leadership.attributes.content}`" />
      </p>
    </div>
  </div>
</template>

<script>
import { baseUrl } from "../Api";
import { mapState, mapActions } from "vuex";

export default {
  created() {
    this.fetchLeadership();
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    ...mapActions(["fetchLeadership"]),
  },
  computed: {
    ...mapState(["leadership"]),
  },
};
</script>

<style lang="scss" scoped></style>
