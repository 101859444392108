<template>
  <div class="max-w-5xl px-4 grid gap-10 md:grid-cols-2" v-if="about.data">
    <img
      :src="getBaseUrl() + about.data.attributes.image.data.attributes.url"
      alt=""
      class="w-full"
    />
    <div>
      <h2 class="text-5xl uppercase font-extrabold">About Me</h2>
      <div class="mt-10">
        <p class="leading-relaxed">
          <VueShowdown :markdown="`${about.data.attributes.content}`" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { baseUrl } from "../Api";
import { mapState, mapActions } from "vuex";

export default {
  created() {
    this.fetchAbout();
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    ...mapActions(["fetchAbout"]),
  },
  computed: {
    ...mapState(["about"]),
  },
};
</script>

<style scoped></style>
