<template>
  <div class="max-w-5xl px-4">
    <h2 class="text-5xl uppercase font-extrabold">The starting point</h2>
    <img
      v-if="startingPoint.attributes"
      :src="getBaseUrl() + startingPoint.attributes.image.data.attributes.url"
      class="h-auto mt-10"
      alt="leadership"
    />
  </div>
</template>

<script>
import { baseUrl } from "../Api";

import { mapState, mapActions } from "vuex";

export default {
  created() {
    this.fetchStartingPoint();
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    ...mapActions(["fetchStartingPoint"]),
  },
  computed: {
    ...mapState(["startingPoint"]),
  },
};
</script>

<style scoped></style>
