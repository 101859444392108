import axios from "axios";
import { baseUrl } from "../Api";

// A single Axios instance for our entire app
const apiClient = axios.create({
  // Base URL for all calls to use
  baseURL: baseUrl,
  withCredentials: false,
  // For authentication and configuration
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  getIntro() {
    return apiClient.get(`api/intro?populate=*`);
  },
  getAbout() {
    return apiClient.get(`api/about?populate=*`);
  },
  getLeadership() {
    return apiClient.get(`api/leadership`);
  },
  getWhatTheySay() {
    return apiClient.get(`api/what-they-say?populate=*`);
  },
  getStartingPoint() {
    return apiClient.get(`api/starting-point?populate=*`);
  },
  getContact() {
    return apiClient.get(`api/contact`);
  },
  getOffers() {
    return apiClient.get(`api/offers?populate=*`);
  },
};
