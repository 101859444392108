import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../src/assets/css/tailwind.css";

Vue.config.productionTip = false;

import VueShowdown from "vue-showdown";

// the second parameter of Vue.use() is optional
Vue.use(VueShowdown, {
  // set default flavor of showdown
  flavor: "github",
  // set default options of showdown (will override the flavor options)
  options: {
    emoji: false,
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
