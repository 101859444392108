<template>
  <div class="max-w-5xl px-4">
    <h2 class="text-5xl uppercase font-extrabold">What they say</h2>
    <div class="grid gap-10 md:grid-cols-2 mt-10" v-if="whatTheySay.attributes">
      <img
        :src="getBaseUrl() + whatTheySay.attributes.image.data.attributes.url"
        alt=""
      />

      <div>
        <p>
          <VueShowdown :markdown="`${whatTheySay.attributes.content}`" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { baseUrl } from "../Api";
import { mapState, mapActions } from "vuex";

export default {
  created() {
    this.fetchWhatTheySay();
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    ...mapActions(["fetchWhatTheySay"]),
  },
  computed: {
    ...mapState(["whatTheySay"]),
  },
};
</script>

<style lang="scss" scoped></style>
