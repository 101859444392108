import Vue from "vue";
import Vuex from "vuex";

import DataService from "@/service/DataServices";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    intro: [],
    leadership: [],
    startingPoint: [],
    about: [],
    contact: [],
    whatTheySay: [],
    offers: [],
  },
  getters: {},
  mutations: {
    SET_INTRO(state, intro) {
      state.intro = intro;
    },
    SET_LEADERSHIP(state, leadership) {
      state.leadership = leadership;
    },
    SET_STARTING_POINT(state, startingPoint) {
      state.startingPoint = startingPoint;
    },
    SET_ABOUT(state, about) {
      state.about = about;
    },
    SET_CONTACT(state, contact) {
      state.contact = contact;
    },
    SET_WHAT_THEY_SAY(state, whatTheySay) {
      state.whatTheySay = whatTheySay;
    },
    SET_OFFERS(state, offers) {
      state.offers = offers;
    },
  },
  actions: {
    fetchIntro({ commit }) {
      DataService.getIntro()
        .then((response) => {
          commit("SET_INTRO", response.data.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching intros:" + error.message);
        });
    },
    fetchLeadership({ commit }) {
      DataService.getLeadership()
        .then((response) => {
          commit("SET_LEADERSHIP", response.data.data);
        })
        .catch((error) => {
          console.log(
            "There was a problem fetching leadership:" + error.message
          );
        });
    },
    fetchStartingPoint({ commit }) {
      DataService.getStartingPoint()
        .then((response) => {
          commit("SET_STARTING_POINT", response.data.data);
        })
        .catch((error) => {
          console.log(
            "There was a problem fetching startup point:" + error.message
          );
        });
    },
    fetchAbout({ commit }) {
      DataService.getAbout()
        .then((response) => {
          commit("SET_ABOUT", response.data.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching about:" + error.message);
        });
    },
    fetchContact({ commit }) {
      DataService.getContact()
        .then((response) => {
          commit("SET_CONTACT", response.data.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching contact:" + error.message);
        });
    },
    fetchWhatTheySay({ commit }) {
      DataService.getWhatTheySay()
        .then((response) => {
          commit("SET_WHAT_THEY_SAY", response.data.data);
        })
        .catch((error) => {
          console.log(
            "There was a problem fetching testimonials:" + error.message
          );
        });
    },
    fetchOffers({ commit }) {
      DataService.getOffers()
        .then((response) => {
          commit("SET_OFFERS", response.data.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching offers:" + error.message);
        });
    },
  },
  modules: {},
});
