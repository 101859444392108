<template>
  <div class="max-w-5xl px-4 pb-10">
    <div class="grid gap-10 sm:gap-60 sm:grid-cols-2 mt-10">
      <h2 class="text-5xl uppercase font-extrabold">
        <span class="block">Let's</span>
        <span>collaborate.</span>
      </h2>
      <div v-if="contact.attributes">
        <VueShowdown :markdown="`${contact.attributes.content}`" />
      </div>
    </div>
  </div>
</template>

<script>
import { baseUrl } from "../Api";
import { mapState, mapActions } from "vuex";

export default {
  created() {
    this.fetchContact();
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    ...mapActions(["fetchContact"]),
  },
  computed: {
    ...mapState(["contact"]),
  },
};
</script>

<style lang="scss" scoped></style>
