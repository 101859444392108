<template>
  <div
    id="app"
    class="grid place-items-center min-h-screen gap-y-20 sm:gap-y-52 bg-callaway-primary text-gray-200"
  >
    <div
      class="px-4 max-w-5xl pt-10 grid gap-4 sm:grid-cols-2"
      v-if="intro.attributes"
    >
      <div class="grid gap-4">
        <img
          :src="getBaseUrl() + intro.attributes.logo.data.attributes.url"
          alt="callaway"
          class="w-full"
        />
        <div class="text-xl">
          <VueShowdown :markdown="`${intro.attributes.name}`" />
        </div>
      </div>
      <div>
        <img
          :src="getBaseUrl() + intro.attributes.sunrise.data.attributes.url"
          alt="morning"
        />
      </div>
    </div>
    <StartingPoint />
    <Leadership />
    <AboutMe />
    <SectionOffer />
    <SectionTestimonial />
    <Footer />
  </div>
</template>
<script>
import { baseUrl } from "./Api";

import { mapState, mapActions } from "vuex";

import StartingPoint from "@/components/SectionStartingPoint.vue";
import Leadership from "@/components/SectionLeadership.vue";
import AboutMe from "@/components/AboutMe.vue";
import SectionOffer from "@/components/SectionOffer.vue";
import SectionTestimonial from "@/components/SectionTestimonial.vue";
import Footer from "@/components/SectionFooter.vue";

export default {
  components: {
    StartingPoint,
    SectionOffer,
    AboutMe,
    Leadership,
    SectionTestimonial,
    Footer,
  },
  created() {
    this.fetchIntro();
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    ...mapActions(["fetchIntro"]),
  },
  computed: {
    ...mapState(["intro"]),
  },
};
</script>

<style>
#app {
  font-family: "Inter", sans-serif, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
