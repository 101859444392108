<template>
  <div class="pb-10 px-4">
    <div class="max-w-5xl">
      <h2 class="text-5xl uppercase font-extrabold">What i offer</h2>
      <div class="flex flex-wrap mt-10 space-y-14 md:space-y-0">
        <div
          class="w-full px-4 md:w-1/2 lg:w-1/3"
          v-for="offer in offers"
          :key="offer.id"
        >
          <div class="mx-auto mb-10 max-w-[550px]">
            <div class="mb-8 overflow-hidden rounded">
              <img
                :src="getBaseUrl() + offer.attributes.image.data.attributes.url"
                alt="image"
                class="w-full"
              />
            </div>
            <div>
              <h3>
                <a
                  class="mb-4 inline-block font-semibold sm:text-2xl lg:text-xl xl:text-2xl"
                >
                  {{ offer.attributes.title }}
                </a>
              </h3>
              <p class="text-base">
                <VueShowdown :markdown="`${offer.attributes.content}`" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { baseUrl } from "../Api";
import { mapState, mapActions } from "vuex";

export default {
  created() {
    this.fetchOffers();
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    ...mapActions(["fetchOffers"]),
  },
  computed: {
    ...mapState(["offers"]),
  },
};
</script>

<style scoped></style>
